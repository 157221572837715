import React from "react";
import itrameiIcon from "../assets/icons/Itramei_Icon.svg";
import { useDispatch } from "react-redux";
import { setForm } from "../redux/reducers/waiting_list_form";

const WaitListButton = () => {
  const dispatch = useDispatch();

  const handleFormChange = (eventType) => {
    dispatch(setForm(eventType));
  };

  return (
    <div className="flex justify-center items-center mt-6">
      <button
        id="waitlistbtn"
        className="relative flex items-center overflow-hidden group text-[#1F2C38] font-semibold text-lg shadow-md transition-colors duration-300 ease-in-out"
        onClick={(e) => {
          handleFormChange("itramei");
        }}
        style={{
          width: "100%", 
          maxWidth: "320px",
          padding: "12px 20px",
          height: "48px",
          borderRadius: "8px",
          border: "1px solid #293A4B",
          backgroundColor: "#FFFFFF",
          fontFamily: "Sansation, Arial, sans-serif",
          textAlign: "center",
          display: "flex", 
          alignItems: "center", 
          justifyContent: "center",
          gap: "8px", 
        }}
      >
        <span
          className="absolute inset-0 bg-[#1B92BF] transform -translate-x-full transition-transform duration-1000 ease-in-out group-hover:translate-x-0"
          style={{ zIndex: 1 }}
        ></span>
        <img
          src={itrameiIcon}
          alt="Itramei Icon"
          className="w-6 h-8 relative z-10"
        />
        <span className="relative z-10 group-hover:text-white">
          Join the Waitlist Today
        </span>
      </button>
    </div>
  );
};

export default WaitListButton;